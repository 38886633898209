import React, { useEffect, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { FloatButton, Image, Input, Select, Space, Typography } from 'antd'
import {
  LoadingOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons'
import { Row, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import api from '../api'

const { Text, Title } = Typography
const { Option } = Select


const countries = {
  ca: {
    code: 'en-CA',
    currency: 'CAD',
  },
  gb: {
    code: 'en-GB',
    currency: 'GBP',
  },
  us: {
    code: 'en-US',
    currency: 'USD',
  },
  fr: {
    code: 'fr-FR',
    currency: 'EUR',
  },
  jp: {
    code: 'ja-JP',
    currency: 'JPY',
  },
}

const ProductList = (props) => {
  let { countryCode }  = useParams()

  const [search, setSearch] = useSearchParams()
  const [translations, setTranslations] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [categoryFilter, setCategoryFilter] = useState(search.getAll("categories"))
  const [retailerFilter, setRetailerFilter] = useState(search.getAll("retailers"))
  const products = data?.attributes.ProductList
  const noProduct = isEmpty(data?.attributes.ProductList)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (!countries[countryCode]) {
      window.location.href = 'https://loopmobileapp.com/'
      return null
    }

    async function fetchTranslations() {
      const { data } = await api.get(`/translation?locale=${countries[countryCode].code}`)
      setTranslations(data.data?.attributes?.translations)
    }

    async function fetchData() {
      const { data } = await api.get(`/product-catalog?locale=${countries[countryCode].code}&populate[ProductList][populate]=*`)
      setData(data.data)

      setIsLoading(false)
    }

    fetchTranslations()
    fetchData()


  }, [countryCode])

  useEffect(() => {
    setSearch({
      categories: categoryFilter,
      retailers: retailerFilter,
    })
    
  }, [categoryFilter, retailerFilter, setSearch])

  const uniqueCategories = [...new Set(products?.flatMap(product => product.Categories.data.map(category => category.attributes.Name)))]
  const uniqueRetailers = [...new Set(products?.flatMap(product => product.Retailers.data.map(retailer => retailer.attributes.Name)))]

  const filteredProducts = products?.filter(product =>
    product.ProductName.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (categoryFilter?.length === 0 || categoryFilter.some(filter => product.Categories.data.some(category => category.attributes.Name === filter))) &&
    (retailerFilter?.length === 0 || retailerFilter.some(filter => product.Retailers.data.some(retailer => retailer.attributes.Name === filter)))
  )

  if (isLoading) return (
    <Col className="text-center">
      <LoadingOutlined />
    </Col>
  )

  if(noProduct) return (
    <div className="d-flex align-items-center justify-content-center">
      <Title level={4}>
        {data?.attributes.NoProduct}
      </Title>
    </div>
  )

  return (
    <Row className="product-list">
      <Title className="title" level={2}>{data.attributes.ProductListTitle}</Title>

      <Col className="align-items-center filter-wrapper w-100 d-flex justify-content-between mt-2" xs={12}>
        <Col className="search-filter">
          <Input
            className="search-bar"
            onChange={event => setSearchTerm(event.target.value)}
            placeholder={translations.search}
          />
        </Col>
        <Col className="d-flex justify-content-end product-filters">
          <Select
            allowClear
            defaultValue={categoryFilter}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            mode="multiple"
            onChange={value => setCategoryFilter(value)}
            optionFilterProp="children"
            placeholder={translations.selectCategory}
            style={{ marginRight: 15, minWidth: 200 }}
          >
            {uniqueCategories.sort((a, b) => a.localeCompare(b)).map(category => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>

          <Select
            defaultValue={retailerFilter}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            mode="multiple"
            onChange={value => setRetailerFilter(value)}
            optionFilterProp="children"
            placeholder={translations.selectRetailer}
            style={{ minWidth: 200 }}
          >
            {uniqueRetailers.sort((a, b) => a.localeCompare(b)).map(retailer => (
              <Option key={retailer} value={retailer}>{retailer}</Option>
            ))}
          </Select>
        </Col>
      </Col>

      {React.Children.toArray(filteredProducts.map((product) => {
        const normalImgUrl = product.ProductImage.data.attributes.url
        const brandUrl = product.BrandLogo.data.attributes.url

        return (
          <Col className="product align-content-between" md={3} sm={6} xs={12}>
            <Col className="d-flex flex-column align-items-end">
              <Col className="w-100 d-flex flex-row justify-content-between align-items-center">
                <Col
                  className="avatar"
                  style={{ backgroundImage: `url(${brandUrl})`}}
                />

                {product.TagText && (
                  <Badge bg="primary">{product.TagText}</Badge>
                )}
              </Col>

              <Col>
                <Image
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: { onZoomOut, onZoomIn },
                      },
                    ) => (
                      <Space className="toolbar-wrapper">
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      </Space>
                    ),
                  }}
                  src={normalImgUrl}
                />
              </Col>
            </Col>

            <Col className="productCardFooter">
              <Text strong>{product.ProductName}</Text>
              <Text className="brandName">{product.BrandName}</Text>

              <div className="d-flex flex-row align-items-center">
                <Text strong>{translations.deposit}&nbsp;</Text>
                {new Intl.NumberFormat(countries[countryCode].code, { currency: countries[countryCode].currency, style: 'currency' }).format(product.DepositValue)}
              </div>
            </Col>
          </Col>
        )}))}

      <FloatButton.BackTop />
    </Row>
  )
}

export default ProductList
